import "angular";
import "angular-animate";
import "angular-aria";
import "angular-cookies";
import "angular-messages";
import "angular-route";
import "angular-resource";
import "angular-sanitize";
import "angular-material";
import "angular-fixed-table-header";
import "angular-material-data-table";
import "angular-ui-calendar";
import "ng-idle";
import "ngstorage";
